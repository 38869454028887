
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    number: {
      type: Number
    }
  }
})
