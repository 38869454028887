<template>
  <div v-if="projects && projects.length > 0">
    <DashboardSection>
      <template #top-right-button v-if="$route.name !== 'ArchiveProjects'">
        <CustomButton
          @onClick="$router.push({ name: studioId ? 'AddAdminProject' : 'AddProject' })"
          iconClass="plus"
          label="New project"
          color="grey"
          size="small"
        />
      </template>
      <template #content v-if="projects && projects.length > 0">
        <TableLayout>
          <template #left-headings>
            <p class="left-heading-item">Project</p>
            <p class="left-heading-item mobile-hide">Revenue</p>
          </template>
          <template #right-headings>
            <p class="right-heading-item">To dos</p>
            <p class="right-heading-item">SOW</p>
            <p class="right-heading-item">PO</p>
            <p class="right-heading-item">Invoice</p>
          </template>
          <TableTile v-for="project of projects" :key="project.id">
            <template #tileContent>
              <router-link
                :to="{
                  name: studioId ? 'projectDetailsAdmin' : 'ProjectDetails',
                  params: studioId ? { studioId: studioId, id: project.id } : { id: project.id }
                }"
              >
                <div class="left-content">
                  <div class="left-text">
                    <p class="tile-title bold">{{ project.name }}</p>
                    <p class="tile-subtitle">{{ project.client.name }}</p>
                  </div>
                  <p class="mobile-hide">
                    {{ project.totalValue ? intlFormat(project.totalValue) : 0 }}
                  </p>
                </div>
                <div class="right-content">
                  <div><DisplayNumber :number="0" /></div>
                  <div>
                    <img
                      src="@/assets/icons/tick-green.svg"
                      class="green-tick"
                      v-if="
                        project.DocumentsOnProject.length > 0 &&
                        project.DocumentsOnProject.some((doc) => doc.type === 'SOW')
                      "
                    />
                    <img v-else src="@/assets/icons/error.svg" class="error-icon" />
                  </div>
                  <div>
                    <img
                      src="@/assets/icons/tick-green.svg"
                      class="green-tick"
                      v-if="
                        project.DocumentsOnProject.length > 0 &&
                        project.DocumentsOnProject.some((doc) => doc.type === 'PO')
                      "
                    />
                    <img v-else src="@/assets/icons/error.svg" class="error-icon" />
                  </div>
                  <div>
                    <img
                      class="error-icon"
                      v-if="calculateInvoicePercentageForProject(project.invoices) === 0"
                      src="@/assets/icons/error.svg"
                    />
                    <img class="green-tick" v-else src="@/assets/icons/tick-green.svg" />
                  </div>
                </div>
              </router-link>
            </template>
            <template #menu>
              <ul>
                <li
                  @click="
                    $router.push({ name: studioId ? 'EditProjectDetails' : 'EditProject', params: { id: project.id } })
                  "
                >
                  Edit
                </li>
                <li v-if="$route.name == 'ProjectListing'" @click="archiveProject(project.id)">Archive</li>
                <li v-if="$route.name == 'ArchiveProjects'" @click="archiveProject(project.id)">Unarchive</li>
                <li v-if="studioId" @click="deleteProject(project.id)">Delete</li>
              </ul>
            </template>
          </TableTile>
        </TableLayout>
      </template>
    </DashboardSection>
  </div>
  <div class="no-documents" v-else>
    <NoResult title="You haven't added any projects yet">
      <CustomButton
        v-if="!isArch"
        @click="$router.push({ name: studioId ? 'AddAdminProject' : 'AddProject' })"
        label="Add a new project"
        color="blue"
      />
    </NoResult>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ComputedRef } from 'vue'
import TableLayout from '@/components/templates/TableLayout.vue'
import TableTile from '@/components/templates/TableTile.vue'
import DisplayNumber from '@/components/atom/DisplayNumber.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import NoResult from '@/components/molecules/NoResult.vue'
import moment from 'moment'
import { useRoute } from 'vue-router'
import { useStore } from '@/store'
import { intlFormat } from '@/utils/currencyFunctions'
import { MutationTypes as ProjectMutations } from '@/store/projects/mutations'
import { ActionTypes as projectActions } from '@/store/projects/actions'
import authApi from '@/api/Authenticated'
import { projectTypes } from '@shared/*'

export default defineComponent({
  emits: ['deleteProject'],
  components: {
    TableLayout,
    DisplayNumber,
    TableTile,
    CustomButton,
    DashboardSection,
    NoResult
  },
  setup(props, { emit }) {
    const route = useRoute()
    const store = useStore()
    const studioId = route.params.studioId
    const clientId = route.params.id
    const getProject = () => {
      clientId
        ? store.dispatch(projectActions.GET_CLIENT_PROJECTS, clientId)
        : store.dispatch(projectActions.GET_PROJECTS, studioId)
    }
    const isArch = computed(() => route.path.indexOf('archived') > -1)
    const projects: ComputedRef<projectTypes.ProjectResponse[]> = computed(() =>
      isArch.value ? store.getters.archiveProjects : store.getters.activeProjects
    )
    onMounted(getProject)

    const dateFormatter = (date: Date): string => {
      return moment(date).format('YYYY-MM-DD')
    }
    const calculateInvoicePercentageForProject = (invoices: any) => {
      let percentage = 0

      invoices.length > 0 &&
        invoices.map((invoice: any) => {
          if (invoice.status === 'Sent') {
            if (dateFormatter(invoice.date) > dateFormatter(new Date())) {
              percentage += 100 / invoices.length
            }
          } else if (invoice.status === 'Paid') {
            percentage += 100 / invoices.length
          } else if (dateFormatter(invoice.date) > dateFormatter(new Date())) {
            percentage += 100 / invoices.length
          } else {
            percentage = 0
          }
        })

      return Math.round(percentage)
    }

    async function archiveProject(id: number) {
      await authApi.archiveUnarchiveProject(id)
      await store.commit(ProjectMutations.ARCHIVE_PROJECT, +id)
    }
    const deleteProject = (id: number) => {
      emit('deleteProject', id)
    }
    return {
      calculateInvoicePercentageForProject,
      dateFormatter,
      studioId,
      intlFormat,
      archiveProject,
      isArch,
      projects,
      deleteProject
    }
  }
})
</script>

<style lang="sass" scoped>
$leftWidth: 12rem
$rightWidth: 5rem
.tile-tile
  font-weight: bold
  font-size: 16px
.tile-subtitle
  font-size: 12px
  color: map-get($greyShades, 'greyShade80')
.left-content, .right-content
  display: flex
  align-items: center
  margin-left: 0rem
  @media( max-width: $md-breakpoint)
    margin-left: -0.5rem
  & > *
    text-align: center
    word-break: break-all

.left-content > *
  width: $leftWidth
  text-align: left
  @media( max-width: $md-breakpoint)
    width: 7rem
.right-content > *
  min-width: $rightWidth
  @media( max-width: $md-breakpoint)
    min-width: 3rem
.right-heading-item
  min-width: $rightWidth
  @media( max-width: $md-breakpoint)
    min-width: 3rem
.left-heading-item
  width: $leftWidth
  text-align: left
  @media( max-width: $md-breakpoint)
    width: 7rem
  &:first-child
    padding-left: 1rem
.left-text
  text-align: left
.mobile-hide
  @media( max-width: $md-breakpoint)
    display: none
.bold
  font-weight: bold
</style>
