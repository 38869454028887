
import { defineComponent, computed, onMounted, ComputedRef } from 'vue'
import TableLayout from '@/components/templates/TableLayout.vue'
import TableTile from '@/components/templates/TableTile.vue'
import DisplayNumber from '@/components/atom/DisplayNumber.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import NoResult from '@/components/molecules/NoResult.vue'
import moment from 'moment'
import { useRoute } from 'vue-router'
import { useStore } from '@/store'
import { intlFormat } from '@/utils/currencyFunctions'
import { MutationTypes as ProjectMutations } from '@/store/projects/mutations'
import { ActionTypes as projectActions } from '@/store/projects/actions'
import authApi from '@/api/Authenticated'
import { projectTypes } from '@shared/*'

export default defineComponent({
  emits: ['deleteProject'],
  components: {
    TableLayout,
    DisplayNumber,
    TableTile,
    CustomButton,
    DashboardSection,
    NoResult
  },
  setup(props, { emit }) {
    const route = useRoute()
    const store = useStore()
    const studioId = route.params.studioId
    const clientId = route.params.id
    const getProject = () => {
      clientId
        ? store.dispatch(projectActions.GET_CLIENT_PROJECTS, clientId)
        : store.dispatch(projectActions.GET_PROJECTS, studioId)
    }
    const isArch = computed(() => route.path.indexOf('archived') > -1)
    const projects: ComputedRef<projectTypes.ProjectResponse[]> = computed(() =>
      isArch.value ? store.getters.archiveProjects : store.getters.activeProjects
    )
    onMounted(getProject)

    const dateFormatter = (date: Date): string => {
      return moment(date).format('YYYY-MM-DD')
    }
    const calculateInvoicePercentageForProject = (invoices: any) => {
      let percentage = 0

      invoices.length > 0 &&
        invoices.map((invoice: any) => {
          if (invoice.status === 'Sent') {
            if (dateFormatter(invoice.date) > dateFormatter(new Date())) {
              percentage += 100 / invoices.length
            }
          } else if (invoice.status === 'Paid') {
            percentage += 100 / invoices.length
          } else if (dateFormatter(invoice.date) > dateFormatter(new Date())) {
            percentage += 100 / invoices.length
          } else {
            percentage = 0
          }
        })

      return Math.round(percentage)
    }

    async function archiveProject(id: number) {
      await authApi.archiveUnarchiveProject(id)
      await store.commit(ProjectMutations.ARCHIVE_PROJECT, +id)
    }
    const deleteProject = (id: number) => {
      emit('deleteProject', id)
    }
    return {
      calculateInvoicePercentageForProject,
      dateFormatter,
      studioId,
      intlFormat,
      archiveProject,
      isArch,
      projects,
      deleteProject
    }
  }
})
