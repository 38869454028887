<template>
  <div class="display-number">{{ number }}</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    number: {
      type: Number
    }
  }
})
</script>
<style lang="sass" scoped>
.display-number
    border: 1px solid map-get($greyShades, 'greyShade40')
    width: fit-content
    border-radius: 2px
    color: map-get($greyShades, 'greyShade40')
    margin: 0 auto
    padding: 2px
    margin: 0 auto
    min-width: 1.4rem
    min-height: 1.4rem
    display: flex
    align-items: center
    justify-content: center
    font-size: 12px
    line-height: 15.36px
</style>
